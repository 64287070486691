import React, { FormEventHandler, useRef } from "react"
import Container from "../components/Container"
import Navbar from "../components/Navbar"
import useMediaQuery from "../hooks/useMediaQuery"

import SzkolenieDesktop from "../images/szkolenieDesktop.png"
import SzkolenieMobile from "../images/szkolenieMobile.png"
import SzkolenieDesktopEn from "../images/szkolenieDesktop.en.jpg"
import SzkolenieMobileEn from "../images/szkolenieMobile.en.jpg"

import Bg from "../images/bg.webp"

import emailjs from "@emailjs/browser"
import { useTranslation } from "react-i18next"
import { useIsPolish } from "../../i18n"

const szkolenieVat = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)")
  const isPolish = useIsPolish()

  const form = useRef<HTMLFormElement>(null)

  const sendEmail: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    if (!form.current) return

    emailjs
      .sendForm(
        "service_3s1dstg",
        "template_hybtnae",
        form.current,
        "cV4w4uc6VzBgmXQWZ"
      )
      .then(
        result => {
          //   e.target.reset();
          console.log("OK")
          window.location.reload()
        },
        error => {
          console.log(error.text)
        }
      )
  }

  const { t } = useTranslation("vat")

  const banner = (
    isPolish
      ? [SzkolenieDesktop, SzkolenieMobile]
      : [SzkolenieDesktopEn, SzkolenieMobileEn]
  )[isDesktop ? 0 : 1]

  return (
    <div>
      <Navbar />
      <Container>
        <img
          className="shadow-xl z-20 relative mt-32 mx-auto"
          src={banner}
          alt="Baner szkoleniowy"
        />
      </Container>
      <img
        className="w-[80%] lg:w-auto absolute z-10 top-[10rem] left-10"
        src={Bg}
        alt="Background"
        draggable={false}
      />
      <Container>
        <div className="mt-16 z-40 relative">
          <h1 className="text-[#262A33] text-5xl">{t("title1")}</h1>
          <h2 className="text-[#262A33] text-4xl ml-16">{t("title2")}</h2>
          <p className="text-gray-500 font-content my-10">
            {t("p1")} <br /> <br />
            {t("p2")}
            <br /> <br />
            {t("p3")}
          </p>
          <hr />
        </div>

        <div className="mt-16 z-40 relative">
          <h1 className="text-[#262A33] text-5xl">{t("title3")}</h1>
          <h2 className="text-[#262A33] text-4xl ml-16">{t("title4")}</h2>
          <p className="text-gray-500 font-content my-10">
            {t("p4")}
            <br />
            <br />
            {t("p5")}
            <br />
            <br />
            {t("p6")}
          </p>
        </div>
      </Container>
      <section className="w-full bg-gradient-to-t from-darkNavy to-navy shadow-xl text-left min-h-[30rem]">
        <Container>
          <h1 className="text-white text-xl font-content font-bold pt-10">
            {t("form")}
          </h1>
          <form
            className="flex flex-col lg:flex-row"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="flex flex-col w-full">
              <label className="font-content mt-4">{t("fname")}</label>
              <input
                className="rounded-lg w-11/12 h-9 mt-1 font-content text-black px-4"
                type="text"
                name="name"
              />

              <label className="font-content mt-4">{t("fphone")}</label>
              <input
                className="rounded-lg w-11/12 h-9 mt-1 font-content text-black px-4"
                type="text"
                name="phone"
              />

              <label className="font-content mt-4">{t("femail")}</label>
              <input
                className="rounded-lg w-11/12 h-9 mt-1 font-content text-black px-4"
                type="email"
                name="email"
              />
            </div>

            <div className="flex flex-col w-full">
              <label className="font-content mt-4">{t("fmessage")}</label>
              <textarea
                className="rounded-lg w-full h-32 mt-1 font-content text-black px-4"
                name="message"
              ></textarea>
              <div className="flex flex-row justify-end">
                <button
                  type="submit"
                  className="bg-golden px-4 py-2 font-content mb-8 lg:mb-0 mt-4 rounded-md"
                >
                  {t("fsend")}
                </button>
              </div>
            </div>
          </form>
        </Container>
      </section>
      <footer className="w-full h-32 flex justify-center items-center text-gray-500">
        Copyright © 2022 taxharmony.pl
      </footer>
    </div>
  )
}

export default szkolenieVat
